export const leftColumn = [
  { 
    title: "Steps",
    content: 
      [
        "The sequence of events a customer might take within each phase of the journey.",
      ]
  },
  { 
    title: "Attributes",
    content: 
      [
        "The goals customers have for each step. They provide a micro-view into what needs to be focused on.",
      ]
  },
  { 
    title: "Engagement", 
    content: 
      [
        "The importance placed on each step (1. very low priority to 5. very high priority) based on how positive or negative the user’s experience and significance placed on that step.",
      ]
  },
  { 
    title: "Positive/Negative",
    content: 
    [
      "The sentiment is either positive, negative or neutral. The number of positive and negative comments build a picture of the overall experience for that attribute."
    ]
  },
];

export const rightColumn = [
  {
    title: "High engagement + Positive sentiment", 
    content:
    [
      "Confirm that the brand is delivering on this"
    ] 
  },
  {
    title: "High engagement + Negative sentiment", 
    content:
    [
      "High priority opportunity"
    ] 
  },
  {
    title: "Medium engagement +  Positive sentiment", 
    content:
    [
      "Opportunity"
    ] 
  },
  {
    title: "Medium engagement +  Negative sentiment",
    content:
    [
      "Opportunity"
    ] 
  },
  {
    title: "Low engagement + Positive sentiment",
    content:
    [
      "Not a priority but important to maintain good quality"
    ] 
  },
  {
    title: "Low engagement + Negative sentiment",
    content:
    [
      "Opportunity"
    ] 
  },
  {
    title: "Medium/High engagement + Neutral sentiment",
    content:
    [ 
      "Potential future opportunities"
    ] 
  },
  {
    title: "Low engagement + Neutral sentiment",
    content:
    [ 
      "Not a priority"
    ] 
  },
]