import React from 'react';
import { transformChartData } from '../../utils/utils';
import {
  ComposedChart,
  Line,
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import CustomizedAxisTick from '../CustomizedAxisTick';
import './index.scss'

export default function Graph (props) {
  const {data, id} = props;
  const chartData = data && transformChartData(data);

  return (
    <div className='graph-container' id={id} >
      <ResponsiveContainer>
        <ComposedChart
            data={chartData}
            margin={{
              top: 20, right: 80, bottom: 20, left: 20,
            }}
        >
        <CartesianGrid stroke="#F6F6F6" vertical={false}/>
        <XAxis
            key={Math.random()}
            axisLine={false}
            stroke="#fffff"
            dataKey="name"
            tick={<CustomizedAxisTick/>}
            interval={0}
            height={140}
        />
        <YAxis
            axisLine={false}
            stroke="#fffff"
            yAxisId="left"
            label={{ position: 'insideCenter', value: 'No. of Positive/Negative mentions', angle: -90, fontSize: 15}}
            width={110}
        />
        <YAxis 
            axisLine={false}
            stroke="#fffff"
            yAxisId="right"
            orientation="right"
            label={{ position: 'right', value: 'Engagement Level', angle: 90, fontSize: 15}}
            width={40} 
        />
        <Tooltip />
        <Legend
            align="right"
            verticalAlign="top"
            iconSize={12}
        />
        <Bar 
            yAxisId="left" 
            dataKey="Positive" 
            barSize={id === "attributesGraph" ? 12 : 16} 
            legendType="circle" 
            fill={id === "attributesGraph" ? "#9BBB00" : "#006834"} 
            radius={[6, 6, 6, 6]} 
        />
        <Bar 
            yAxisId="left" 
            dataKey="Neutral" 
            barSize={id === "attributesGraph" ? 12 : 16} 
            legendType="circle" 
            fill="#B9B9B9"
            radius={[6, 6, 6, 6]} 
        />
        <Bar 
            yAxisId="left" 
            dataKey="Negative" 
            barSize={id === "attributesGraph" ? 12 : 16} 
            legendType="circle" 
            fill="#807D7D" 
            radius={[6, 6, 6, 6]} 
        />
        <Line 
            yAxisId="right" 
            type="monotone" 
            dataKey="engagement" 
            legendType="circle" 
            stroke="#EAD10C" 
            dot={{strokeWidth: 3}} 
        />
        </ComposedChart>
      </ResponsiveContainer>
    </div> 
  );
}