import React, {PureComponent} from 'react';
import {
  Text
} from 'recharts';
import './index.scss';

export default class CustomizedAxisTick extends PureComponent {
    render() {
        const {x, y, payload, width, height, fontSize, fill} = this.props;
        const label = payload.value;
        const spacedLabel = label.replace("/", "/ ");
        const truncatedLabel = spacedLabel.length > 25 ? spacedLabel.substring(0, 25) + '...' : spacedLabel; 
      return (
        <g transform={`translate(${x},${y+30+height})`}>
          <Text className='customizedAxisTick' width={width} height={height} textAnchor="middle" fontSize={fontSize} fill={fill} >  
            {truncatedLabel}                
          </Text>           
        </g>
      );
    }
  }

CustomizedAxisTick.defaultProps = {
    width: 80,
    height: 20,
    fontSize: 14,
    lineHeight: 25,
    fill: "#333"
}