import React from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { getGroupNames } from '../../utils/utils';
import './index.scss';

export default function AttributeTabs({ tabData, tab, parentTab, onChange }) {
  function handleChange(event, tab) {
    onChange({phaseTab: parentTab, stepTab: `${tab}`});
  }
  const tabs = tabData && getGroupNames(tabData);

  return (
    <div className='attribute-tabs'>
      <Paper square>
        <Tabs
          value={tab}
          onChange={handleChange}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          aria-label="icon label tabs example"
        >
          {tabs && tabs.map((tabComponent, index) => (
              <Tab key={index} label={tabComponent} value={tabComponent} />
            )
          )}  
        </Tabs>
      </Paper>
    </div>
  );
}