import React from 'react';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import './index.scss';
import ResearchAwarenessIcon from '../../assets/AGI_CX_Research_Awarness_icon.svg';
import DescisionIcon from '../../assets/AGI_CX_Decision_icon.svg';
import FinanceIcon from '../../assets/AGI_CX_Finance_icon.svg';
import DeliveryIcon from '../../assets/AGI_CX_Delivery_icon.svg';
import MaintainanceIcon from '../../assets/AGI_CX_Maintainance_icon.svg';
import AnalysisIcon from '../../assets/AGI_CX_Analysis_icon.svg';
import DataSharingIcon from '../../assets/AGI_CX_DataSharing_icon.svg';

export default function JourneyTabs({ tabType, tab, parentTab, onChange, currentJourney }) {
  function handleChange(event, tab) {
    tabType === 'journey' ?
    onChange({phaseTab: `${tab}`}) : 
    onChange({phaseTab: parentTab, stepTab: `${tab}`});
  }

  if (currentJourney === 'Producer Equipment Purchase') {
    return (
      <div className='journey-tabs'>
        <Paper square>
          <h4>JOURNEYS</h4>
          <p class='sub-sub-title'>{currentJourney}</p>
           <Divider variant="middle" className="journeyDivider" />
  
            <Tabs
              className="tabs"
              value={tab}
              onChange={handleChange}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
              aria-label="icon label tabs example"
            >
            <Tab icon={<img src={ResearchAwarenessIcon} alt='' width='40' />} label="RESEARCH & AWARENESS" value='Research & Awareness' />
             <Divider orientation="vertical" component="li"/>
             <Tab icon={<img src={DescisionIcon} alt='' width='40' />} label="CONSIDERATION & DECISION" value='Consideration & Decision' />
             <Divider orientation="vertical" component="li"/>
             <Tab icon={<img src={FinanceIcon} alt='' width='40' />} label="FINANCE & PAYMENT" value='Finance & Payment' />
             <Divider orientation="vertical" component="li"/>
             <Tab icon={<img src={DeliveryIcon} alt='' width='40' />} label="DELIVERY" value='Delivery' />
             <Divider orientation="vertical" component="li"/>
             <Tab icon={<img src={MaintainanceIcon} alt='' width='40' />} label="SUPPORT & MAINTENANCE" value='Support & Maintenance' />
            </Tabs>
        </Paper>
      </div>  
    );
  } else {
      return (
        <div className='journey-tabs'>
          <Paper square>
            <h4>JOURNEYS</h4>
            <p class='sub-sub-title'>{currentJourney}</p>
            <Divider variant="middle" className="journeyDivider" />
    
              <Tabs
                className="tabs"
                value={tab}
                onChange={handleChange}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                aria-label="icon label tabs example"
              >
              <Tab icon={<img src={DescisionIcon} alt='' width='40' />} label="CONSIDERATION" value='Consideration of New Data Tools' />
              <Divider orientation="vertical" component="li"/>
              <Tab icon={<img src={ResearchAwarenessIcon} alt='' width='40' />} label="DATA COLLECTION" value='Data Collection' />
              <Divider orientation="vertical" component="li"/>
              <Tab icon={<img src={AnalysisIcon} alt='' width='40' />} label="ANALYSIS" value='Analysis' />
              <Divider orientation="vertical" component="li"/>
              <Tab icon={<img src={DataSharingIcon} alt='' width='40' />} label="DATA SHARING" value='Data Sharing' />
              </Tabs>
          </Paper>
        </div>  
      );
  }
}
