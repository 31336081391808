import React from 'react';
import { Link } from 'gatsby';
import './index.scss';

export default function VerticalSlider(props) {
  const scrollToElement = require('scroll-to-element')

  function handleLinkClick (e, target) {
    if (typeof window !== undefined) {
      
      if (window.location.pathname === '/') {
        e.preventDefault()
        scrollToElement(target, {
          offset: -90,
          duration: 1000,
        })
      }
    }
  } 

  return (
    <React.Fragment>
      <div className='stepper'>
        <ul>
          <li>
            <h5>
              <Link onClick={e => handleLinkClick(e, `#top`)}>
                JOURNEYS
              </Link>
            </h5>
          </li>
          <li>
            <h5>
              <Link onClick={e => handleLinkClick(e, `#steps`)}>            
                STEPS
              </Link>
            </h5>
          </li>
          <li>
            <h5>
              <Link onClick={e => handleLinkClick(e, `#attributes`)}>
                ATTRIBUTES
              </Link>
            </h5>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
}