// From first series get x labels
export function getGroupNames(rawData) {
  return rawData[0].xnames
}

// Construct data
export function transformChartData(rawData) {
    let groupNames = getGroupNames(rawData);  
    let nseries = rawData.length;
    let ngroups = groupNames.length;
    var data = new Array();
    for(var g=0; g<ngroups; g++) {
      data[g] = {'name': groupNames[g]};
      for(var i=0; i<nseries; i++) {
        let series = rawData[i]
        let name = series.name;
        data[g][name] = series.data[g];
      }
    }
    return data;
}