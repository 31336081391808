import React from "react";
import { Button } from "@material-ui/core";
import { useFetch, useInitialize } from '../api/useFetch';
import { useAuth } from "react-use-auth";
import {
  AttributeTabs,
  DescriptionTable,
  Layout,
  SEO,
  SubHeader,
  JourneyTabs,
  Graph,
  Stepper,
} from './../components';
import Divider from "@material-ui/core/Divider";
import './index.scss';

const IndexPage = () => {
  const { isAuthenticated, user } = useAuth();
  const whiteList = ['applydigital.co', 'aggrowth.com', ''];

  const initValues = useInitialize();
  const savedInitValues = initValues && initValues.initialize.journeys;
  const savedUserEmail = isAuthenticated() ? user && user.email : '';
  const userVerified = user && user.email_verified;
  const emailSplit = savedUserEmail.split('@');
  
  const userHasAccess = () => {
    return userVerified && whiteList.some((domain) => {
      return emailSplit[emailSplit.length - 1].toLowerCase() === domain;
    })
  }

  // Data for journey and attribute tabs
  const [journey, setJourney] = React.useState({ journeyName: 'Producer Equipment Purchase'});  
  const [tabs, setTabs] = React.useState({ phaseTab: 'Research & Awareness', stepTab: 'Farm Planning'});
  
  const journeyKey = journey.journeyName === 'Producer Equipment Purchase' ? 0 : 1;
  const dashName = journeyKey === 0 ? 'dash1' : 'dash2';

  // Defaults and current
  const defaultPhase = savedInitValues && savedInitValues[journeyKey].defaultPhase;
  const defaultStep = savedInitValues && savedInitValues[journeyKey].phases[0].defaultStep;
  const currentPhase = tabs.phaseTab ? tabs.phaseTab : defaultPhase;
  const currentStep = tabs.stepTab ? tabs.stepTab : defaultStep;
  const phases = savedInitValues && savedInitValues[journeyKey].phases;

  // Data for graphs
  const phaseData = useFetch(dashName, 'PHASE', currentPhase, currentStep);
  const rawPhaseData = phaseData && phaseData.get_chart.data;

  const stepData = useFetch(dashName, 'STEP', currentPhase, currentStep);
  const rawStepData = stepData && stepData.get_chart.data;

  if (userHasAccess() === false) {
    return (
      <Layout journeyName={journey.journeyName} setJourney={setJourney} isAuthenticated={isAuthenticated} setTabs={setTabs} >
        {<LoggedOut userHasAccess={userHasAccess} />} 
      </Layout>
    )
  } else {
    return (
      <Layout journeyName={journey.journeyName} setJourney={setJourney} isAuthenticated={isAuthenticated} userHasAccess={userHasAccess} setTabs={setTabs} >
        {!isAuthenticated() && <LoggedOut userHasAccess={userHasAccess} />}
        {isAuthenticated() && <LoggedIn journeyName={journey.journeyName} tabs={tabs} setTabs={setTabs} rawPhaseData={rawPhaseData} rawStepData={rawStepData} phases={phases} />}
      </Layout>
    )
  } 
}

const LoggedOut = ( {userHasAccess} ) => {
  const { login } = useAuth()
  return (
    <div className='login-screen'>
      <div className='log-in'>
        {userHasAccess() === false ? <h3 className='log-in-header'>You are not authorized to access this dashboard</h3> : <h3 className='log-in-header'>You have successfully logged out</h3>}
        {userHasAccess() === false ? <p className='log-in-text'>Please ensure you verified your account through Auth0's confirmation email, or connect with the website administrator for assistance</p> : <p className='log-in-text'>Please log in through Auth0 to gain access to the dashboard</p>}
        <Button
          class="login-button"
          onClick={login}
          variant="outlined"
        >
          LOGIN
        </Button>
      </div>
    </div>
  )
}

const LoggedIn = ({journeyName, tabs, setTabs, rawPhaseData, rawStepData, phases}) => {
  return (
    <div className='logged-in-screen'>
      <div className='nav-stepper' >
        <Stepper />
      </div>
      <div className='main-container' id='top'>
        <SEO title="Home" />
        <JourneyTabs tabType='journey' tab={tabs.phaseTab} onChange={setTabs} currentJourney={journeyName} />
        <div className='innerMain'>
          <DescriptionTable id='dTab' tab={tabs.phaseTab} phases={phases} />
          <SubHeader id='steps' subTitle='STEPS' subsubTitle={tabs.phaseTab} />
          <Divider variant="middle" />
          <Graph data={rawPhaseData} id="stepsGraph" />
          <SubHeader id='attributes' subTitle='ATTRIBUTES' subsubTitle={tabs.phaseTab} />
          <Divider variant="middle" />
          <AttributeTabs tabData={rawPhaseData} tab={tabs.stepTab} parentTab={tabs.phaseTab} onChange={setTabs} />
          <Graph data={rawStepData} id="attributesGraph" />
        </div>
      </div>
    </div>
  )
}



export default IndexPage
