import PropTypes from "prop-types";
import React from "react";
import FAQDialog from '../FAQDialog';
import './index.scss';

const SubHeader = ({ subTitle, subsubTitle, id }) => {
  return(
    <div id={id} className='subheader-top'>
    <header className='subheader'>
      <div className='subheader-container'>
        <div className='subheader-title-help'>
          <h4 className='subheader-title'>{subTitle}</h4>
          <FAQDialog />
        </div>
        <div className='subheader-icon-row'>
          <p class='sub-sub-title'>{subsubTitle}</p>
        </div>
      </div>
    </header>
    </div>
  )
}
  
  SubHeader.propTypes = {
    subTitle: PropTypes.string,
  }
  
  SubHeader.defaultProps = {
    subTitle: ``,
  }
  
  export default SubHeader