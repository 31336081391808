import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import HelpIcon from '../../assets/question-circle.svg';
import Svg from 'react-inlinesvg';
import { leftColumn, rightColumn } from '../../assets/faq';
import './index.scss';


export default function FAQDialog() {
    const styles = theme => ({
        root: {
          margin: 0,
          padding: '1rem 2rem 0 2rem'
        },
        closeButton: {
          position: 'absolute',
          right: theme.spacing(1),
          top: theme.spacing(1),
          color: theme.palette.grey[500],
        },
      });
      
      const DialogTitle = withStyles(styles)(props => {
        const { children, classes, onClose, ...other } = props;
        return (
          <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h1">{children}</Typography>
            {onClose ? (
              <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </MuiDialogTitle>
        );
      });
      
      const DialogContent = withStyles(theme => ({
        root: {
          padding: '0 2rem 2rem 2rem',
          display: 'flex',
    
        },
      }))(MuiDialogContent);

    const [open, setOpen] = useState(false);
  
    const handleClickOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
  
    return (
      <div className="help-icon">
        <Svg src={HelpIcon} onClick={handleClickOpen} fill='#006834' />
        <Dialog maxWidth="md" onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            <h3>How to Read This Dashboard</h3>
          </DialogTitle>
          <DialogContent>
            <div className="leftGrid">
              {leftColumn.map((item) => (
                <div className="text-pair">
                  <div className="sub-header">
                    <h3>{item.title}</h3>
                  </div>
                  <div className="body-text">
                    {item.content.map((body) => ( 
                      <p>{body}</p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <Divider orientation="vertical" component="div" />
            <div className="rightGrid">
              {rightColumn.map((item) => (
                <div className="text-pair">
                  <div className="sub-header">
                    <ul>{item.title}</ul>
                  </div>
                  <div className="body-text">
                    {item.content.map((body) => ( 
                      <p>{body}</p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }