import fetch from 'isomorphic-fetch';
import { useState, useEffect } from 'react';

// Change url for deploy 
 const url = '/graphql';
//const url = 'http://127.0.0.1:5000/graphql';

export function useInitialize () {
  const query = `
  {
    initialize {
      journeys {
        id
        phases {
          name
          detail
          steps {
            name
            attributes
          }
          defaultStep
        }
        defaultPhase
      }
    }
  }`;
  const [initData, setInitData] = useState(null);

  useEffect(() => {
    const getInitData = async () => {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({ query }),
        credentials: "same-origin",
      });
      const finishedResponse = await response.json()
      setInitData(finishedResponse.data);
    };
    getInitData();
  }, []);
  return initData;
}

export function useFetch (dashName, chartType, phase, step) {
  const query = `
  {
      get_chart(
        input: {
          chartType: ${chartType}
          phase: ${JSON.stringify(phase)}
          step: ${JSON.stringify(step)}
          journey: ${JSON.stringify(dashName)}
        }
      ) {
        phase
        step
        data {
          name
          xnames
          data
        }
      }
  }`;
  const [data, setData] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({ query }),
        credentials: "same-origin",
      });
      const finishedResponse = await response.json()
      setData(finishedResponse.data);
    };
    getData();
  }, [chartType, phase, step, dashName]);
  
  return data;
}