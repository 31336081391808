import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import './index.scss';

export default function DescriptionTable(props) {
    const { tab, phases } = props;
    const getCurrentIndex = (phases, tab) => {
        let current = 0;
        phases && phases.map((phase, index) => {
            if (phase.name === tab) {
                current = index;
            }
        })
        return current;
    }
    const currentIndex = getCurrentIndex(phases, tab);
    const currentPhase = phases && phases[currentIndex];
  
  return (
    <div className='description-table' id='dTab'>
        <Paper className='paper'>
            <Table className='table'>
            <colgroup>
      <col style={{width:'40%'}}/>
      <col style={{width:'60%'}}/>
   </colgroup>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <span className='journey-header'>Phase</span>
                        </TableCell>                  
                        <TableCell>
                            <span className='steps-header'>Steps</span>
                            <span className='attributes-header'>Attributes</span>
                        </TableCell>
                    </TableRow>
                </TableHead>
                
                <TableBody>   
                    <TableRow key={tab}>
                        <TableCell className='journeyDesc'>
                            <h6>{currentPhase && currentPhase.name}</h6>
                            <p>{currentPhase && currentPhase.detail}</p>
                        </TableCell>

                         <TableCell align="left" component="th" scope="row">
                            {currentPhase && currentPhase.steps.map(step => (
                                <TableRow>
                                    <TableCell>
                                        <ul>{step.name}</ul>
                                    </TableCell>
                                    <TableCell align="left">                                                               
                                        {(step.attributes).map(attribute => (     
                                            <li>{attribute}</li>
                                        ))}                                                                                                  
                                    </TableCell>  
                                </TableRow>                              
                            ))}
                        </TableCell> 
                    </TableRow> 
                </TableBody> 
            </Table>
        </Paper> 
    </div>
    
  );
}